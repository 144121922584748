import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import StoreAdapter from '../StoreAdapter';
import store from 'store2';

class CustomerComment extends StoreAdapter {

    constructor(props) {
        super(props);
        this.state = {};
        this.timeout = null;
        this.cancelableChangeComment = this.cancelableChangeComment.bind(this);
    }
    mapStateToProps = (state) => {
        return {
            data: state
        }
    };

    emptyComment = (props) => {
            return (
                <React.Fragment>
                    <textarea id="customerComment" className="w-100" onChange={
                        (e) => {
                            clearTimeout(this.timeout);
                            this.text = e.currentTarget.value;
                            this.timeout = setTimeout(() => {
                                this.cancelableChangeComment(this.text);
                            }, 500);
                        }} placeholder="Hier können Sie zusätzliche Informationen (z.B. Farbwünsche) zu Ihrer Bestellung vermerken.">{props.data.customerComment}</textarea>
                </React.Fragment>
            );
    }

    cancelableChangeComment = (newComment) =>  {
        setTimeout(() => {this.ChangeComment(newComment);}, 500);
    }

    ChangeComment = async (newComment) =>{
        await axios.post('/api.php',{
            api: 'v0.1',
            authentication: {username: null, password: null},
            csrfToken: null,
            clTRID: null,
            apiModule: 'Shopingo',
            apiName: 'Cart',
            apiMethod: 'updateCustomerComment',
            data: {
                customerComment: newComment,
            }
        }).then(response => {
            setTimeout(() => {
                document.getElementById('customerComment').focus();
                store.session(false);
                this.refreshStore('cart');
                this.refreshStore('cartlist');
            }, 100);
            this.setState({comment: response});
        });
    }

    connectedList = connect(this.mapStateToProps)(this.emptyComment);
    render() {
        return (
             <this.connectedList/>
        );
    }
}

export default CustomerComment;