import axios from 'axios';
import store from 'store2';

const SUCCESS = 'SUCCESS';

export const fetchCartListData = () => (dispatch, getState) => {
  dispatch({ type: 'FETCH_API_REQUESTED' });
    if ('ShopCartListStore' in store.session()){
      dispatch(
        {
          type: 'FETCH_API_SUCCESS',
          payload: JSON.parse(sessionStorage.getItem('ShopCartListStore')),
        }
      );
      return SUCCESS;
    }
  axios
    .get(`/api/shopingo/cart/item/read`)
    .then((response) => {
      dispatch({
        type: 'FETCH_API_SUCCESS',
        payload: response.data.data
      });
      return response;
    }).then((response) => {
      store.session('ShopCartListStore', response.data.data);
     }
   )
    .catch((error) => {
      dispatch({
        type: 'FETCH_API_FAILURE',
        error: true,
        payload: error.response,
      });
    });
};

export const setProductFade = (id) => (dispatch) => {
  dispatch({
    type: 'SET_PRODUCT_FADE',
    payload: id
  });
}