
import axios from 'axios';
import store from 'store2';

const SUCCESS = 'SUCCESS';

export const fetchCartData = () => (dispatch, getState) => {
  dispatch({ type: 'FETCH_REPOS_REQUESTED' });
    if ('ShopCartStore' in store.session()){
      dispatch(
        {
          type: 'FETCH_REPOS_SUCCESS',
          payload: JSON.parse(sessionStorage.getItem('ShopCartStore')),
        }
      );
      return SUCCESS;
    }
    axios
      .get(`/api/shopingo/cart/read`)
      .then((response) => {
        dispatch({
          type: 'FETCH_REPOS_SUCCESS',
          payload: response.data.data[0]
        })
        return response;
      }).then((response) => {
         store.session('ShopCartStore', response.data.data[0]);
        }
      )
      .catch((error) => {
        dispatch({
          type: 'FETCH_REPOS_FAILURE',
          error: true,
          payload: error,
        });
      });
  };