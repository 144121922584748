import React from 'react';
import { connect } from 'react-redux';

class CartPriceContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    mapStateToProps = (state) => {
        return {
            data: state
        }
    };

    price = (props) => {
        if(props.data.articleCount > 0){
            var priceTaxDisplay = (parseFloat(props.data.priceTax).toFixed(2)).replace('.', ',');
            return (
                <React.Fragment>
                     <div  className="cartSumSticky pt-2">
                        <div className="container-fluid clearfix">
                            <div className="cartOverview">
                                <div className="d-table-cell text-left text-lg-right">
                                    <strong>Zwischensumme</strong>
                                </div>
                                <div className="d-table-cell text-right">
                                    <span className="price">{props.data.priceNettoDisplay}&nbsp;{props.data.currencySymbol}</span>
                                </div>
                            </div>
                            <div className="cartOverview">
                                <div className="d-table-cell text-left text-lg-right">
                                    <strong>MwSt.</strong>
                                </div>
                                <div className="d-table-cell text-right">
                                    <span className="price">{priceTaxDisplay}&nbsp;{props.data.currencySymbol}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="cartSumSticky" className="cartSumSticky py-2">
                        <div className="container-fluid clearfix">
                            <div className="cartOverview">
                                <div className="d-table-cell text-left text-lg-right">
                                    <strong>Gesamtsumme</strong>
                                    <span className="tax-info"> Inkl.&nbsp;MwSt.</span>
                                </div>
                                <div className="d-table-cell text-right">
                                    <span className="price"><strong>{props.data.priceBruttoDisplay}&nbsp;{props.data.currencySymbol}</strong></span>
                                </div>
                            </div>
                            <div className="cartOverview dNoBySticky p-1"></div>
                        </div>
                        <div className="container-fluid">
                            <div className="row justify-content-between">
                                <div class="col"></div>
                                    <div className="col-md-6 col-lg-4 ">
                                    <a title="zur Kasse" className="btn btn-block shopButton p-2" href="/checkout/confirm.html">zur Kasse</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </React.Fragment>
            );
        }else{
            return (
                null
            );
        }
    }


    connectedPrice = connect(this.mapStateToProps)(this.price);
    render() {
        return (
             <this.connectedPrice/>
        );
       
    }
}

export default CartPriceContainer;