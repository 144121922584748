import store from 'store2';

const SUCCESS = 'SUCCESS';

export const readBookmarkData = () => (dispatch) => {
    if ('BookmarkList' in store.session()){
      dispatch(
        {
          type: 'READ_BOOKMARK_SES_STORE',
          payload: JSON.parse(sessionStorage.getItem('BookmarkList')),
        }
      );
      return SUCCESS;
    }
    dispatch(
      {
        type:'READ_BOOKMARK_STORE',
      }
    )
  };

  export const incrementProduct = (key, amount) => (dispatch) => {
    dispatch(
      {
        type: 'INCREMENT_BOOKMARK_AMOUNT',
        payload: {
          key: key, amount: amount
        },
      }
    );
  }

  export const decrementProduct = (key, amount) => (dispatch) => {
    dispatch(
      {
        type: 'DECREMENT_BOOKMARK_AMOUNT',
        payload: {
          key: key, amount: amount
        },
      }
    );
  }

  export const setProductAmount = (key, newAmount) => (dispatch) => {
    dispatch(
      {
        type: 'SET_BOOKMARK_AMOUNT',
        payload: {
          key: key, amount: newAmount
        },
      }
    );
  }

  export const addProduct = (product) => (dispatch) => {
    dispatch(
      {
        type:'ADD_BOOKMARK',
        payload: product
      }
    );   
}

  export const removeProduct = (id) => (dispatch) =>{
    dispatch(
      {
        type:'REMOVE_BOKMARK',
        payload: id
      }
    );
  }

  export const setProductFade = (id) => (dispatch) => {
    dispatch({
      type: 'SET_PRODUCT_FADE',
      payload: id
    });
  }

  export const clearStore = () => (dispatch) => {
    dispatch(
      {
        type: 'CLEAR_DATA',
        payload: []
      }
    );
  }