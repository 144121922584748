import React from 'react';
import axios from 'axios';
import store from 'store2';

import { connect } from 'react-redux';

import StoreAdapter from './StoreAdapter';

class ProductCounter extends StoreAdapter {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            apiId: props.apiId,
            test: props.test,
        };
        this.value = 1;
        this.useValue = false;
        this.interval = null;
        this.quantityAction = this.quantityAction.bind(this);
        this.cancelableAction = this.cancelableAction.bind(this);
    }


    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };

    counter = (props) => {
        this.value = props.article.quantity;
        if(!Number.isInteger(this.state.value)){
            return (
                <React.Fragment>
                    <input type="text" pattern="[0-9]{1,2}" name="XXX" 
                    onChange={
                        (e) => {
                            this.setState({value: parseInt(e.currentTarget.value)});
                            this.quantityAction(e.currentTarget.value, props.article.id);
                        }}
                         value={this.value}/>
                </React.Fragment>
            );
        }

        if(this.useValue){
            return (
                <React.Fragment>
                    <input type="text" pattern="[0-9]{1,2}" name="XXX" 
                    onChange={
                        (e) => {
                            clearTimeout(this.interval);
                            this.setState({value: parseInt(e.currentTarget.value)});
                            this.cancelableAction(e.currentTarget.value, props.article.id);
                        }} value={this.value}/>
                </React.Fragment>
            ); 
        }
        return (
            <React.Fragment>
                <input type="text" pattern="[0-9]{1,2}" name="XXX" 
                onChange={
                    (e) => {
                        clearTimeout(this.interval);
                        this.setState({value: parseInt(e.currentTarget.value)});
                        this.interval = setTimeout(() => {
                            this.cancelableAction(this.state.value, props.article.id);
                        }, 1000);
                    }}/>
            </React.Fragment>
        );
    }

    cancelableAction = (value, id) => {
        setTimeout(() => {this.quantityAction(value, id);}, 1000);
    }

    quantityAction = async (quantity, id) =>{
        await axios.post('/api/shopingo/cart/item/update',
        JSON.stringify({
            quantity: quantity, 
            id: id
        }));

        store.session(false);

        this.refreshStore('cartlist');
        this.refreshStore('cart');
    }

    connectedProduct = connect(this.mapStateToProps)(this.counter);
    render() {
        return (
           <this.connectedProduct/>
        );
    }
}

export default ProductCounter;