import React from 'react';
import { connect, Provider } from 'react-redux';
import Items from './Items';

import { bookmarkStore } from '../../Stores/Bookmark'
import { readBookmarkData } from '../../Actions/Bookmark';
import AddBookmark  from './AddBookmark';

class CartList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }
    mapStateToProps = (state) => {
        return {
            data: state
        }
    };

    emptyList = (props) => {
        if(  typeof props.data !== 'undefined' && props.data.length > 0 ){
            var price = 0.00;
            props.data.map((product) => {
                price += parseFloat(product.priceNetto.replace(',', '.')) * product.amount;
            });
            price = price.toLocaleString('de-DE',{minimumFractionDigits: 2});
            return (
            <React.Fragment>
                <div id="shopCartList" className="clearfix">
                    <div>
                        <ul id="shopCartItemList">
                            <Items/>
                        </ul>
                        <div class="position-relative">
                            <ul className="shopCartItemList">
                                <li className="shopCartSum">
                                    <AddBookmark/>
                                </li>
                            </ul>
                        </div>
                        <ul class="shopCartItemList">
                                <li class="shopCartSum">
                                    <div class="product align-middle text-md-right"><span class="strong">Gesamtpreis aller Artikel</span></div>
                                    <div class="productCalc">
                                        <div><span>{price}&nbsp;€</span></div>
                                    </div>
                                </li>
                            </ul>
                    </div>
                </div>
            </React.Fragment>
        )
        }
        return (
            <React.Fragment>
                <div id="shopCartList" className="clearfix">
                    <div>
                        <div class="position-relative">
                            <ul className="shopCartItemList">
                                <li className="shopCartSum">
                                    <AddBookmark/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );   
    }

    connectedList = connect(this.mapStateToProps)(this.emptyList);
    render() {
        bookmarkStore.dispatch(readBookmarkData());
        return (
             <this.connectedList/>
        );
       
    }
}

export default CartList;