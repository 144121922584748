import React from 'react';
import axios from 'axios';
import store from 'store2';
import { connect } from 'react-redux';

import { shopCartStore } from '../Stores/ShopCart';
import { fetchCartData } from '../Actions/ShopCart';

import { shopCartListStore } from '../Stores/ShopCartList';
import { fetchCartListData, setProductFade } from '../Actions/ShopCartList';

import StoreAdapter from './StoreAdapter';

class RemoveItem extends StoreAdapter {

    constructor(props) {
        super(props);
        this.state = {
            test: props.test,
        };
        this.removeAction = this.removeAction.bind(this);
    }

    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };

    article = (props) => {
        return (
            <React.Fragment>
                 <button type="button" className="deleteBtn" onClick={(e) => {this.removeAction(props.article.id)}}/>
            </React.Fragment>
        );
    }

    removeAction = async (id) => {
        this.setProductFading(this.state.test);

                await axios.post('/api/shopingo/cart/item/destroy',
                JSON.stringify({
                id: id
        }));

        store.session(false);
        this.refreshStore('cartlist');
        this.refreshStore('cart');
    }


    connectedRemove = connect(this.mapStateToProps)(this.article);

    render() {
        return (
            <this.connectedRemove/>
        );
    }
}

export default RemoveItem;