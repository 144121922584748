import React from 'react';
import axios from 'axios';
import store from 'store2';

import StoreAdapter from './StoreAdapter';

class AddItem extends StoreAdapter {

    constructor(props) {
        super(props);
        this.addAction = this.addAction.bind(this);
        
    }

    getArticleId = () => {
        return document.querySelector('[name="Shopingo[article_id]"]').getAttribute("value");
    }

    getVariantId = () => {
        return document.getElementById('addToCartButton').getAttribute('data-variantid');
    }

    getCatalogId = () => {
        return document.querySelector('[name="Shopingo[catalog_id]"]').getAttribute("value");
    }

    getQuantity = () => {
        return document.querySelector('[name="Shopingo[quantity]"]').value;
    }

    article = () => {
        return (
            <React.Fragment>
                <button title="In den Warenkorb" class="btn btn-block shopButton jsMarginBody js-moveButton" onClick={(e) => {this.addAction(e);}}>
                    <span id="addToCart" class="addToCart-label">In den Warenkorb</span>
                    <span class="shopIcon-checked addedToCart" id="addedToCart"></span>
                </button>

            </React.Fragment>
        );
    }

    addAction = async e => {
        await axios.post('/api/shopingo/cart/item/create',
        JSON.stringify(
            {
                articleId: this.getArticleId(),
                variantId: this.getVariantId(),
                catalogId: this.getCatalogId(),
                quantity: this.getQuantity()
            }

        )).then(response => {
            //Überprüfe ob httpstatus 200, wenn nicht reagier drauf - (erst später, da die api unklar ist)
        });
        /*
        1: {
                articleId: 6829,
                variantId: 7205,
                catalogId: 1,
                quantity: 1
            }
        */
       var waitTimer = 1500;
      // var add2CartButton = document.getElementsByClassName('js-moveButton');
        if(document.getElementById('addToCartButton').style.position == 'fixed'){
            waitTimer = 4400;
        }
       setTimeout((e) => {
            store.session(false);

            this.refreshStore('cartlist');
            this.refreshStore('cart');
       },waitTimer);
        
    }

    render() {
        return (
            <this.article/>
        );
    }
}

export default AddItem;