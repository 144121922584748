import React from 'react';
import { connect } from 'react-redux';

import Items from './Items';

class MobileCartList extends React.Component {

    constructor(props) {
        super(props);
    }

    mapStateToProps = (state) => {
        return {
            data: state,
        };
    };

    itemList = (props) => {
        return (
            <React.Fragment>
                <thead>
                    <tr>
                        <th className="name h6">
                            Artikel
                        </th>
                        <th className="total h6">
                            Summe
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <Items/>
                </tbody>
            </React.Fragment>
        );
    }
    
    connectedCartList = connect(this.mapStateToProps)(this.itemList);

    render() {
        return (
            <React.Fragment>
                <this.connectedCartList />
            </React.Fragment>
        );
    }
    
}

export default MobileCartList;