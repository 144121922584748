import React from 'react';
import { connect } from 'react-redux';

class MobilePriceContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    mapStateToProps = (state) => {
        return {
            data: state
        }
    };

    price = (props) => {
            return (
                <React.Fragment>
                    <div className="pricesArticles">
                        <span className="pricesArticleText">Zwischensumme:</span>
                        <span className="pricesArticleAmount">{props.data.priceNettoDisplay}&nbsp;{props.data.currencySymbol}</span>
                    </div>
                </React.Fragment>
            );
    }


    connectedPrice = connect(this.mapStateToProps)(this.price);
    render() {
        return (
             <this.connectedPrice/>
        );
       
    }
}

export default MobilePriceContainer;