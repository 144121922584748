import React from 'react';
import { connect } from "react-redux";

class MobileCartCounter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.articleCount = 0;
    }

    mapStateToProps = (state) => {
        return {
            data: state
        }
    };

    //TODO: Lagere den Code des Buttons in eine Funktion aus, damit er nicht doppelt verwendet wird.
    count (props){
        if(props.data.articleCount > 0){
            return (
                <React.Fragment>
                    <span class="bubbleWrapper">
                        <button  id="cart-icon" class="" type="button" aria-controls="cart-menu"></button>
                        <span class="bubble cartBubble">{props.data.articleCount}</span>
                    </span>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <span class="bubbleWrapper">
                    <button disabled id="cart-icon" class="" type="button" aria-controls="cart-menu"></button>
                    <span className="bubble cartBubble"></span>
                    <span  className="hamburger hamburger--collapse is-active"  style={{'margin-top': '15px'}}>
                        <span className="hamburger-box">
                            <span className="hamburger-inner"></span>
                        </span>
                    </span>
                </span>
            </React.Fragment>
        );
    }

    ConnectedCounter = connect(this.mapStateToProps)(this.count);

    render() {
        return <this.ConnectedCounter/>;
    }

}

export default MobileCartCounter;