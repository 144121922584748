import React from 'react';
import { connect } from 'react-redux';
import { Provider } from 'react-redux';

import Items from './Items';


import { shopCartListStore } from '../../Stores/ShopCartList';
import MobilePriceContainer from './MobilePriceContainer';

import StoreAdapter from '../StoreAdapter';

class MobileCartList extends StoreAdapter {

    constructor(props) {
        super(props);
    }

    mapStateToProps = (state) => {
        return {
            data: state,
        };
    };

    itemList = (props) => {

        if(props.data.articleCount > 0){
            return (
                <React.Fragment>
                    <div className="hasItems">
                        <div className="itemContainer">
                            <Provider store={shopCartListStore}>
                                <Items/>
                            </Provider>
                        </div>
                        <div className="priceContainer">
                            <MobilePriceContainer/>
                            <p className="pricesTax">
                                zzgl. MwSt., <a title="Versandkosten" href="/versandkosten.html">zzgl.&nbsp;Versandkosten</a>
                            </p>
                        </div>
                        <div className="buttonContainer">
                            <a href="/checkout/confirm.html" className="btn btn-block shopButton" title="Zur Kasse">
                                <i className="shopIcon-arrow-right float-right"></i>
                                Zur Kasse
                            </a>
                            <a href="/checkout/cart.html" className="btn btn-block shopSecondaryButton" title="Warenkorb bearbeiten">
                                <i className="shopIcon-arrow-right float-right"></i>
                                Warenkorb bearbeiten
                            </a>
                        </div>
                    </div>
                </React.Fragment>
            );
        }else{
            return (
                <div class="hasNoItems"><br />
                    <p class="text-center mt-5 px-2">Sie haben keine Artikel im Warenkorb.</p>
                </div>
            );
        }
    }
    
    connectedCartList = connect(this.mapStateToProps)(this.itemList);

    render() {
        this.refreshStore('cartlist');
        return (
            <React.Fragment>
                <this.connectedCartList />
            </React.Fragment>
        );
    }
    
}

export default MobileCartList;