

import React from 'react';
import { connect } from 'react-redux';

class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.key,
            test: props.test,
            articleId: props.articleId || null,
            articleName: props.articleName || '',
            articleImages: props.articleImages || [],
            articlenumber: props.articlenumber || '',
            priceNetto: props.priceNetto || 0.00,
            priceNettoTotal: props.priceNettoTotal || 0.00,
            priceBrutto: props.priceBrutto || 0.00,
            priceBruttoTotal: props.priceBruttoTotal || 0.00,
            priceNettoDisplay: props.priceNettoDisplay || 0.00,
            priceNettoTotalDisplay: props.priceNettoTotalDisplay || 0.00,
            priceBruttoDisplay: props.priceBruttoDisplay || 0.00,
            priceBruttoTotalDisplay: props.priceBruttoTotalDisplay || 0.00,
            catalogId: props.catalogId || null,
            quantity: props.quantity || 1,
            apiId: props.apiId,
        };
    }

    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };

    item = (props) => {
        if (props.article.articleId != null) {
            var variantDetails = null;
            if (props.article.variantId != null) {
                variantDetails = (<React.Fragment>{props.article.variantType} {props.article.variantName}</React.Fragment>);
            }
            return (
                <tr>
                    <td className="name"><span className="qty">{props.article.quantity}x </span>{props.article.articleName} {variantDetails}</td>
                    <td className="total">{props.article.priceNettoTotalDisplay}&nbsp;€</td>
                </tr>
            );
        } else {
            return (
                null
            );
        }
    }
    connectedProduct = connect(this.mapStateToProps)(this.item);
    render() {
        return (
            <this.connectedProduct />
        );

    }
}

export default Item;