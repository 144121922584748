import React from 'react';
import axios from 'axios';
import store from 'store2';

import { bookmarkStore } from '../../Stores/Bookmark';
import { readBookmarkData, incrementProduct, addProduct } from '../../Actions/Bookmark';
import {imageMissingUrl, imagePrefixUrl} from '../../Variables';

class AddBookmark extends React.Component {

    constructor(props) {
        super(props);
        this.addAction = this.addAction.bind(this);
        this.findProduct = this.findProduct.bind(this);
        this.state = {
            error: false,
            errorMessage: 'Produkt nicht gefunden!',
            articleExists: false,
            article: null,
            addCounter: 1,
        }

        this.savedArtNum = null;
        this.currentCounter = 1;
        this.interval = null;
        this.quantityToAdd = 1;
        this.setQuantityToAdd = this.setQuantityToAdd.bind(this);
        }
        artNumInput = (artNum) => (
            <div className="product">
                 <input id="articleNumber" type="number" placeholder="Artikelnummer" value={artNum} onChange={
                    (e) => {
                        clearTimeout(this.interval);
                        this.setState({articleNumber: e.currentTarget.value});
                        this.savedArtNum = e.currentTarget.value;
                        this.interval = setTimeout(() => {
                            this.findProduct(this.state.articleNumber);
                        }, 1000);
                    }} onKeyDown={
                        (e) => {
                            if(e.key === 'Enter') {
                                this.addAction();
                            }
                        }
                    }>
                </input> 
                </div>
        );
        addProductRow = (artNum) =>(
            <React.Fragment>
            {this.artNumInput(artNum)}
                    <div className="product d-none d-md-table-cell" style={{width: "130px"}}>
                        <div class="plusminus input" data-trigger-reload="false">
                            <a class="decrementValue" href="#" rel="nofollow" onClick={(e) => { this.setQuantityToAdd(-1)}}> − </a>
                                <input type="number" name="Shopingo[quantity]" id="qty" value={this.state.addCounter} class="" min="1" max="99" data-base-value="1" 
                                onChange={
                                    (e) => {
                                        clearTimeout(this.interval);
                                        var currentInput = 1;
                                        if(e.currentTarget.value > 0){
                                            currentInput = e.currentTarget.value;
                                        }
                                        this.setState({addCounter: currentInput});
                                    }
                                }/>
                                <a class="incrementValue" href="#" rel="nofollow" onClick={(e) => { this.setQuantityToAdd(1)}}> + </a>
                        </div>
                    </div>
            </React.Fragment>
        );

    article = () => {
        var addButton = null;
        if(this.state.error){
            addButton = <p className="m-0">{this.state.errorMessage}</p>
        }
        var itemData = null;
        if(this.state.article !== null){
            addButton = (
                <button title="zur Kasse" class="btn btn-block shopSecondaryButton py-2" onClick={(e) => {this.addAction()}}>Hinzufügen</button>
            );
            var imageUrl = null;
            if('variantId' in this.state.article){
                if(typeof this.state.article.images[0] !== 'undefined'){
                    imageUrl = imagePrefixUrl + this.state.article.images[0]
                }else{
                    imageUrl = imageMissingUrl;
                }
            itemData = (
                <div style={{'overflow': 'hidden'}}>
                    <div className="productPopover" role="tooltip">
                        <div className="arrow"></div>

                        <div className="popover-body row mx-n1">
                            <div className="col-4 px-1">
                                <img src={imageUrl} className="img-fluid" alt={this.state.article.articleName}/>
                            </div>
                            <div className="col-8 px-1">
                                <p><strong>{this.state.article.articleName} </strong><br/>{this.state.article.variantType}: {this.state.article.variantName}</p>
                                <p>Preis: {this.state.article.priceNetto}€</p>
                            </div>
                        </div>
                    </div>
                </div>
            );
            }
            
        }
        if(this.state.articleExists){
            return (
                <React.Fragment>
                    {itemData}
                    {this.addProductRow(this.state.articleNumber)}
                    <div class="productCalc" style="min-width: 150px;background: #f0f0f0;" style={{'min-width': '150px', background: '#f0f0f0'}}>
                        {addButton}
                    </div>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {this.addProductRow(this.state.articleNumber)}
                <div class="productCalc" style="min-width: 150px;background: #f0f0f0;" style={{'min-width': '150px', background: '#f0f0f0'}}>
                    {addButton}
                </div>
            </React.Fragment>
        );
    }

    setQuantityToAdd = (quantity) => {
        if(this.state.addCounter + quantity >= 1 && this.state.addCounter  + quantity <= 99){
            this.setState({addCounter: this.state.addCounter + quantity});
            return;
        }
    }

    findProduct = async (articlenumber) => {
        await axios.post('/api.php',{
            api: 'v0.1',
            authentication: {username: null, password: null},
            csrfToken: null,
            clTRID: null,
            apiModule: 'Shopingo',
            apiName: 'Article',
            apiMethod: 'queryItem',
            data: {
                articleNumber: articlenumber,
                catalogId: 1
            }
        }).then(response => {
            setTimeout(() => {
                document.getElementById('articleNumber').focus();
            }, 100);
            if(!('articleId' in response.data.data)){
                this.setState({error: true, articleExists: false, article: null});
                return;
            }
            this.setState({articleExists: true, article: response.data.data});
        });
    }

    addAction = () => {
        if(this.state.article !== null){
            var article = this.state.article;
                article.amount = this.state.addCounter;
                var productInArray = false;

                var sessionStore = bookmarkStore.getState();
                sessionStore.map((arrayArticle, index) => {
                    if(arrayArticle.articleNumber === article.articleNumber){
                        bookmarkStore.dispatch(incrementProduct(index, this.state.addCounter));
                        this.setState({addCounter: 1});
                        productInArray = true;
                    }
                }
            );
        
            if(!productInArray){
                bookmarkStore.dispatch(addProduct(article));
            }
        
            store.session('BookmarkList', bookmarkStore.getState());
            bookmarkStore.dispatch(readBookmarkData());
            this.setState({article: null, articleExists: false, addCounter: 1, articleNumber: null});
        }
        
    }

    render() {
        return (
            <this.article/>
        );
    }
}

export default AddBookmark;