import update from 'react-addons-update';

const initialState = [];

export const shopCartList = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRODUCT_FADE': {
      return update(state, {
        [action.payload]: {
          fade: {$set: true}
        }
      })
    }
    case 'FETCH_API_REQUEST': {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case 'FETCH_API_SUCCESS': {
      return state = action.payload
    }
    case 'FETCH_API_FAILED': {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};