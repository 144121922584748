import update from 'react-addons-update';

const initialState = [];

export const bookmark = (state = initialState, action) => {
  switch (action.type) {
    case 'READ_BOOKMARK_SES_STORE': {
      return state = action.payload
    }
    case 'READ_BOOKMARK_STORE': {
      return state;
    }
    case 'INCREMENT_BOOKMARK_AMOUNT': {
      return state = update(state, {
        [action.payload.key]: {
          amount: {$set: state[action.payload.key].amount + action.payload.amount}
        }
      });
    }
    case 'DECREMENT_BOOKMARK_AMOUNT': {
      return state = update(state, {
        [action.payload.key]: {
          amount: {$set: state[action.payload.key].amount - action.payload.amount}
        }
      });
    }
    case 'SET_BOOKMARK_AMOUNT': {
      return state = update(state, {
        [action.payload.key]: {
          amount: {$set: action.payload.amount}
        }
      });
    }
    case 'ADD_BOOKMARK': {
        return state = update(state, {
            $push: [action.payload]
          }
        )
    }
    case 'REMOVE_BOKMARK': {
      var productList = [];
      state.map((product, index) => {
        if(index !== action.payload){
          productList.push(product);
        }
      })
      return productList;
    }
    case 'SET_PRODUCT_FADE': {
      return update(state, {
        [action.payload]: {
          fade: {$set: true}
        }
      })
    }
    case 'CLEAR_DATA': {
      return state = action.payload;
    }
    default: {
      return state;
    }
  }
};