import React from 'react';
import store from 'store2';


import ProductCounter from './ProductCounter';

import { bookmarkStore } from '../../Stores/Bookmark';
import { readBookmarkData, incrementProduct, decrementProduct, addProduct } from '../../Actions/Bookmark';

class UpdateAmount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            test: props.test,
        };
        this.quantityAction = this.quantityAction.bind(this);
    }



    //ANzahl wird im kästchen nur durch enter taste neu geladen, muss verbessert
    updateButtons = () => {
        return (
            <React.Fragment>
                <button type="button" className="decrementBtn" onClick={(e) => this.quantityAction(1, this.state.test, 'decrement')}/>
                <ProductCounter test={this.state.test}/>
                <button type="button" className="incrementBtn" onClick={(e) => this.quantityAction(1, this.state.test, 'increment')}/>
            </React.Fragment>
        );
    }

    quantityAction = async (amount, key, mode) =>{
        mode = mode.toLowerCase();
        switch(mode){
            case 'increment':
                bookmarkStore.dispatch(incrementProduct(key, amount));
            break;
            case 'decrement':
                if(bookmarkStore.getState()[key].amount - amount >= 1){
                    bookmarkStore.dispatch(decrementProduct(key, amount));
                }
            break;
        }
        store.session('BookmarkList', bookmarkStore.getState());
    }

    render() {
        return (
           <this.updateButtons/>
        );
    }
}

export default UpdateAmount;