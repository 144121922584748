import React from 'react';
import store from 'store2';
import { connect } from 'react-redux';

import { bookmarkStore } from '../../Stores/Bookmark';
import { removeProduct, setProductFade } from '../../Actions/Bookmark'
import { bookmark } from '../../Reducers/Bookmark';

class RemoveItem extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            test: props.test,
        };
        this.removeAction = this.removeAction.bind(this);
    }

    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };

    article = (props) => {
        return (
            <React.Fragment>
                 <button type="button" className="deleteBtn" onClick={(e) => {this.removeAction(this.state.test)}}/>
            </React.Fragment>
        );
    }

    removeAction = (id) => {
        bookmarkStore.dispatch(setProductFade(id));
        setTimeout(() => {
            bookmarkStore.dispatch(removeProduct(id));
            store.session('BookmarkList', bookmarkStore.getState());
        }, 250);
    }


    connectedRemove = connect(this.mapStateToProps)(this.article);

    render() {
        return (
            <this.connectedRemove/>
        );
    }
}

export default RemoveItem;