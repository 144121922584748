import React from 'react';
import { connect } from 'react-redux';
import UpdateAmount from './UpdateAmount';
import RemoveItem from './RemoveItem';
import {imageMissingUrl, imagePrefixUrl} from '../../Variables';

//Produkt des Warenkorbs, soll beim aufruf die nötigen Werte (Id, Titel, Preis etc..) erhalten, sonst werden defaults verwendet.

class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.key,
            test: props.test,
            articleId: props.articleId || null,
            articleName: props.articleName || '',
            articleImages: props.articleImages || [],
            articlenumber: props.articlenumber || '',
            priceNetto: props.priceNetto || 0.00,
            priceNettoTotal: props.priceNettoTotal || 0.00,
            priceBrutto: props.priceBrutto || 0.00,
            priceBruttoTotal: props.priceBruttoTotal || 0.00,
            priceNettoDisplay: props.priceNettoDisplay || 0.00,
            priceNettoTotalDisplay: props.priceNettoTotalDisplay || 0.00,
            priceBruttoDisplay: props.priceBruttoDisplay || 0.00,
            priceBruttoTotalDisplay: props.priceBruttoTotalDisplay || 0.00,
            catalogId: props.catalogId || null,
            quantity: props.quantity || 1,
            apiId: props.apiId,
        };
    }

    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };
    item = (props) => {
        if(props.article.articleId != null){
            var imageUrl = null;
            if(typeof props.article.images[0] !== 'undefined'){
                imageUrl = imagePrefixUrl + props.article.images[0]
            }else{
                imageUrl = imageMissingUrl; //globale variable aufrufen
            }
            var fadeClass = '';
            if(typeof props.article.fade !== 'undefined' && props.article.fade){
                fadeClass = ' fade';
            }
            var liClasses = "shopCartItem" + fadeClass;
            var price = (parseFloat(props.article.priceNetto.replace(',', '.')) * props.article.amount);
            price = price.toLocaleString('de-DE',{minimumFractionDigits: 2});
            if(props.article.variantId != null){
                return (
                    <li className={liClasses}>
                        <div className="product clearfix">
                            <img src={imageUrl} class="img-responsive d-none d-md-block" alt={props.article.articleName}/>
                            <p><a href="#">{props.article.articleName} <br/>{props.article.variantType} {props.article.variantName}</a><span class="d-none d-md-block">Einzelpreis: {props.article.priceNetto}&nbsp;€ (zzgl.MwSt.)</span><span>(Artikelnummer: {props.article.articleNumber})</span></p>
                            <RemoveItem test={this.state.test}/>
                        </div>
                        <div class="productCalc">
                                        <div>
                                            <div><span>Einzelpreis</span>{props.article.priceNetto}&nbsp;€</div>
                                        </div>
                                        <div>
                                           <UpdateAmount test={this.state.test}/>
                                        </div>
                                        <div><span>Summe</span>{price}&nbsp;€</div>
                                </div>
                    </li>
                );
            }
            return (
                <li className={liClasses}>
                    <div className="product clearfix">
                        <img src={imageUrl} class="img-responsive d-none d-md-block" alt={props.article.articleName}/>
                        <p><a href="#">{props.article.articleName}</a><span class="d-none d-md-block">Einzelpreis: {props.article.priceNetto}&nbsp;€ (zzgl.MwSt.)</span><span>(Artikelnummer: {props.article.articleNumber})</span></p>
                        <RemoveItem test={this.state.test}/>
                    </div>
                    <div class="productCalc">
                                        <div>
                                            <div><span>Einzelpreis</span>{props.article.priceNetto}&nbsp;€</div>
                                        </div>
                                        <div>
                                           <UpdateAmount test={this.state.test}/>
                                        </div>
                                        <div><span>Summe</span>{price}&nbsp;€</div>
                                </div>
                </li>
            );
        }else{
            return (
                null
            );
        }
    }
    connectedProduct = connect(this.mapStateToProps)(this.item);
    render() {
        return (
             <this.connectedProduct/>
        );
       
    }
}

export default Item;