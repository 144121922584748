import React from 'react';

import { shopCartStore } from '../Stores/ShopCart';
import { fetchCartData } from '../Actions/ShopCart';

import { shopCartListStore } from '../Stores/ShopCartList';
import { fetchCartListData, setProductFade } from '../Actions/ShopCartList';

class StoreAdapter extends React.Component {

    constructor(props) {
        super(props);
    }

    refreshStore = (storeName) => {
        storeName = storeName.toLowerCase();
        switch(storeName){
            case 'cart':
                shopCartStore.dispatch(fetchCartData());
            break;
            case 'cartlist':
                shopCartListStore.dispatch(fetchCartListData());
            break;
            default: 
                console.log('storeName ' + storeName + ' not found');
            break;
        }
    }

    setProductFading = (id) => {
        shopCartListStore.dispatch(setProductFade(id));
    }



    render() {
        return (
            null
        );
    }
}

export default StoreAdapter;