import React from 'react';
import axios from 'axios';
import store from 'store2';

import { connect } from 'react-redux';

import { bookmarkStore } from '../../Stores/Bookmark';
import { readBookmarkData, setProductAmount } from '../../Actions/Bookmark';

class ProductCounter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            id: props.id,
            apiId: props.apiId,
            test: props.test,
        };
        this.value = 1;
        this.interval = null;
        this.amountInput = this.amountInput.bind(this);
        this.useValue = false;
    }


    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };

    counter = (props) => {
        this.value = props.article.amount;
        if(!Number.isInteger(this.state.value)){
            return (
                <React.Fragment>
                    <input type="text" name="XXX" 
                    onChange={
                        (e) => {
                            if(parseInt(e.currentTarget.value)<1){
                                return;
                            }
                            this.setState({value: parseInt(e.currentTarget.value)});
                            if(!Number.isNaN(e.currentTarget.value)){
                                this.amountInput(this.state.test, e.currentTarget.value);
                            }
                        }}
                         value={this.value}/>
                </React.Fragment>
            );
        }

        if(this.useValue){
            return (
                <React.Fragment>
                    <input type="text" name="XXX" 
                    onChange={
                        (e) => {
                            clearTimeout(this.interval);
                            if(parseInt(e.currentTarget.value)<1){
                                return;
                            }
                            this.setState({value: parseInt(e.currentTarget.value)});
                            if(!Number.isInteger(this.state.value)){
                                this.setState({value: 1});
                                this.useValue = true;
                            }else{
                                this.useValue = false;
                            }
                            this.amountInput(this.state.test, this.state.value);
                        }} value={this.value}/>
                </React.Fragment>
            ); 
        }

        return (
            <React.Fragment>
                <input type="text" name="XXX" 
                onChange={
                    (e) => {
                        clearTimeout(this.interval);
                        if(parseInt(e.currentTarget.value)<1){
                            return;
                        }
                        this.setState({value: parseInt(e.currentTarget.value)});
                        if(!Number.isInteger(this.state.value)){
                            this.setState({value: 1});
                            this.useValue = true;
                        }
                        this.interval = setTimeout(() => {
                            this.amountInput(this.state.test, this.state.value);
                        }, 1000);
                    }}/>
            </React.Fragment>
        );
    }

    amountInput = async (id, amount) => {
        if(isNaN(amount)){
            return;
        }

        bookmarkStore.dispatch(setProductAmount(id, amount));
        store.session('BookmarkList', bookmarkStore.getState());
    }

    connectedProduct = connect(this.mapStateToProps)(this.counter);
    render() {
        return (
           <this.connectedProduct/>
        );
    }
}

export default ProductCounter;