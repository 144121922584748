import React from 'react';
import { connect } from "react-redux";

import StoreAdapter from '../StoreAdapter';

class CartCounter extends StoreAdapter {

    constructor(props) {
        super(props);
        this.state = {};
        this.articleCount = 0;
    }

    mapStateToProps = (state) => {
        return {
            data: state
        }
    };
    //TODO: Lagere den Code des Buttons in eine Funktion aus, damit er nicht doppelt verwendet wird.
    count (props){
        if(props.data.articleCount > 0){
            return (
                <React.Fragment>
                    <a href="/checkout/cart.html" className="button" aria-label="Shopping cart" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Warenkorb">
                        <i className="shopIcon-cart"></i>
                        <span id="shopCartCounter" className="shopBadge">{props.data.articleCount}</span>
                    </a>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <button type="button" className="noProducts" aria-label="Shopping cart" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Warenkorb">
                    <i className="shopIcon-cart"></i>
                    <span id="shopCartCounter" className="shopBadge"/>
                </button>
            </React.Fragment>
        );
    }

    ConnectedCounter = connect(this.mapStateToProps)(this.count);

    render() {
        this.refreshStore('cart');
        return <this.ConnectedCounter/>;
    }

}

export default CartCounter;