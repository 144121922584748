import React from 'react';
import { connect } from 'react-redux';
import DecrementItem from '../DecrementItem';
import RemoveItem from '../RemoveItem';
import {imageMissingUrl, imagePrefixUrl} from '../../Variables';

class Item extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: props.key,
            test: props.test,
            articleId: props.articleId || null,
            articleName: props.articleName || '',
            articleImages: props.articleImages || [],
            articlenumber: props.articlenumber || '',
            priceNetto: props.priceNetto || 0.00,
            priceNettoTotal: props.priceNettoTotal || 0.00,
            priceBrutto: props.priceBrutto || 0.00,
            priceBruttoTotal: props.priceBruttoTotal || 0.00,
            priceNettoDisplay: props.priceNettoDisplay || 0.00,
            priceNettoTotalDisplay: props.priceNettoTotalDisplay || 0.00,
            priceBruttoDisplay: props.priceBruttoDisplay || 0.00,
            priceBruttoTotalDisplay: props.priceBruttoTotalDisplay || 0.00,
            catalogId: props.catalogId || null,
            quantity: props.quantity || 1,
            apiId: props.apiId,
        };
    }

    mapStateToProps = (state) => {
        var product = state[this.state.test]
        return {
            article: product
        }
    };
    item = (props) => {
        
        if(props.article.articleId != null){
            var imageUrl = null;
            var articleDetailLink = '/checkout/cart-detail,3,a,' + props.article.articleId + '.html';
            var variantDetail = null;

            if(typeof props.article.articleImages !== 'undefined' && props.article.articleImages !== []){
                imageUrl = imagePrefixUrl + props.article.articleImages['image_1'];   
            }else{
                imageUrl = imageMissingUrl;
            }
            var fadeClass = '';
            if(typeof props.article.fade !== 'undefined' && props.article.fade){
                fadeClass = ' fade';
            }
            var liClasses = "shopCartItem" + fadeClass;

            if(props.article.variantId != null){
                var variantDetail = (<React.Fragment><br/>{props.article.variantType} {props.article.variantName}</React.Fragment>);
            }
            return (
                <li className={liClasses}>
                    <div className="product clearfix">
                        <img src={imageUrl} class="img-responsive d-none d-md-block" alt={props.article.articleName}/>
                        <p><a href={articleDetailLink}>{props.article.articleName} {variantDetail}</a><span class="d-none d-md-block">{props.article.priceNettoDisplay}&nbsp;€ (zzgl.MwSt.)</span><span>(Artikelnummer: {props.article.articlenumber})</span></p>
                        <RemoveItem test={this.state.test}/>
                    </div>
                    <div class="productCalc">
                        <div>
                            <div>
                                <span>Einzelpreis</span> {props.article.priceNettoDisplay}&nbsp;€
                            </div>
                        </div>
                        <div>
                            <DecrementItem id={this.state.key} test={this.state.test} apiId={props.article.id} articleId={props.article.articleId}/>
                        </div>
                        <div><span>Summe</span>{props.article.priceNettoTotalDisplay}&nbsp;€</div>
                    </div>
                </li>
            );
            
        }else{
            return (
                null
            );
        }
    }
    connectedProduct = connect(this.mapStateToProps)(this.item);
    render() {
        return (
             <this.connectedProduct/>
        );
       
    }
}

export default Item;