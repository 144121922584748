import React from 'react';
import axios from 'axios';
import store from 'store2';
import { connect } from 'react-redux';

import { bookmarkStore } from '../../Stores/Bookmark';
import { setProductFade } from '../../Actions/Bookmark';
import { clearStore } from '../../Actions/Bookmark';
import StoreAdapter from '../StoreAdapter';

class AddToNotice extends StoreAdapter {

    constructor(props) {
        super(props);
        this.addToNoticeAction = this.addToNoticeAction.bind(this);
    }

    mapStateToProps = (state) => {
        return {
            products: state
        }
    }

    article = (props) => {
        if(Number.isNaN(props.products.length) || !Number.isNaN(props.products.length) && props.products.length === 0){
            document.getElementById('addListToNoticeButton').classList.add('noProducts');
        }else{
            document.getElementById('addListToNoticeButton').classList.remove('noProducts');
            return (
                <React.Fragment>
                     <button title="In den Warenkorb" className="btn btn-block shopButton jsMarginBody js-moveButton" onClick={(e) => {this.addToNoticeAction()}} >
                            In den Merkzettel
                    </button>
                </React.Fragment>
            );
        }
        return (
            null
        );
    }

    addToNoticeAction = async () => {
        var apiProductList = [];
        var animationProductList = [];
        bookmarkStore.getState().map((product, index) => {
            var editedProduct = {
                articleId: product.articleId,
                variantId: product.variantId,
                //catalogId: product.catalogId,
                quantity: product.amount
            }
            apiProductList.push(editedProduct);
            animationProductList.push(index);
        });
        await axios.post('/api/shopingo/notice/item/create',
        JSON.stringify(
            apiProductList
        ),
        /*{
            headers: {module: 'Shopingo', event: 'addNoticeOrderFormItems', mod_resource_id: '1'}
        }*/).then(response => {
            //Überprüfe ob httpstatus 200, wenn nicht reagier drauf - (erst später, da die api unklar ist)
        });
   
        setTimeout((e) => {
            animationProductList.reverse().map((product) => {
                setTimeout(() => {
                    bookmarkStore.dispatch(setProductFade(product));
                }, 20);
                //reversed array as map, to let the fade out begin at the end of the page
                
            })
        }, 700);

        setTimeout((e) => {
            store.session(false);
            bookmarkStore.dispatch(clearStore());
            this.refreshStore('cart');
            this.refreshStore('cartlist');
        }, 1100);


    }
    connectedList = connect(this.mapStateToProps)(this.article);
    render() {
        return (
            <this.connectedList/>
        );
    }
}

export default AddToNotice;